<template>
  <div class="about">
      <breadcrumbs></breadcrumbs>
    <h1>This is an about page</h1>
  </div>
</template>
<script>
    import Breadcrumbs from "../components/partials/Breadcrumbs";
    export default {
        components: {Breadcrumbs}
    }
</script>
